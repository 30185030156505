// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-category-js": () => import("../src/templates/Category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-tag-js": () => import("../src/templates/Tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-story-js": () => import("../src/templates/Story.js" /* webpackChunkName: "component---src-templates-story-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-direitos-dos-autores-js": () => import("../src/pages/direitos-dos-autores.js" /* webpackChunkName: "component---src-pages-direitos-dos-autores-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-linha-do-tempo-da-historia-islamica-js": () => import("../src/pages/linha-do-tempo-da-historia-islamica.js" /* webpackChunkName: "component---src-pages-linha-do-tempo-da-historia-islamica-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-quem-somos-js": () => import("../src/pages/quem-somos.js" /* webpackChunkName: "component---src-pages-quem-somos-js" */),
  "component---src-pages-search-js": () => import("../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-tags-js": () => import("../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */)
}

